import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	CurrentRouteInfoSymbol,
	IAppDidMountHandler,
	IAppWillLoadPageHandler,
	IPropsStore,
	IStructureAPI,
	Props,
	SiteFeatureConfigSymbol,
	StructureAPI,
} from '@wix/thunderbolt-symbols'
import { TpaSrcBuilderSymbol, ITpaSrcBuilder, TpaContextMappingSymbol, ITpaContextMapping } from 'feature-tpa-commons'
import { name } from './symbols'
import { ITpaWorker, TpaWorkerSiteConfig } from './types'
import { ICurrentRouteInfo } from 'feature-router'
import { createPromise } from '@wix/thunderbolt-commons'

export const TPA_WORKER_PREFIX = 'tpaWorker'

type CreateWorkerArgs = {
	applicationId: string
	appDefinitionId: string
	appWorkerUrl: string
	appDefinitionName: string
}

export const TpaWorkerFactory = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		StructureAPI,
		Props,
		TpaSrcBuilderSymbol,
		TpaContextMappingSymbol,
		CurrentRouteInfoSymbol,
	],
	(
		{ tpaWorkers }: TpaWorkerSiteConfig,
		structureApi: IStructureAPI,
		props: IPropsStore,
		tpaSrcBuilder: ITpaSrcBuilder,
		tpaContextMapping: ITpaContextMapping,
		currentRouteInfo: ICurrentRouteInfo
	): IAppDidMountHandler & ITpaWorker & IAppWillLoadPageHandler => {
		const tpaWorkerCompIdRegex = new RegExp(`^${TPA_WORKER_PREFIX}_([0-9]+)$`)

		const createWorker = async ({
			applicationId,
			appDefinitionId,
			appWorkerUrl,
			appDefinitionName,
		}: CreateWorkerArgs): Promise<string> => {
			const workerId = `${TPA_WORKER_PREFIX}_${applicationId}`
			tpaContextMapping.registerTpasForContext('masterPage', [workerId])
			props.update({
				[workerId]: {
					title: appDefinitionName,
					src: tpaSrcBuilder.buildSrc(workerId, 'masterPage', {}, appWorkerUrl, {
						extraQueryParams: {
							endpointType: 'worker',
						},
						appDefinitionId,
					}),
				},
			})

			await structureApi.addComponentToDynamicStructure(workerId, {
				components: [],
				componentType: 'TPAWorker',
			})
			return workerId
		}

		const isTpaWorker = (compId: string) => tpaWorkerCompIdRegex.test(compId)

		const {
			resolver: resolveNavigationToNonProtectedPagePromise,
			promise: waitForNavigationToNonProtectedPage,
		} = createPromise()

		return {
			/* we want to wait for the entire site to be ready before adding the workers, this is why we use appDidMount.
				appDidLoadPage is only used to prevent special cases where tpaWorker on site and the starting page is
				a protected page, normally the tpa should not be in the structure at all but because of how tpaWorker
				is inflated it will be and start fire handlers while the site is not really ready yet (for example
				calling getSiteInfo when currentRouteInfo has not been resolved yet)
			 */
			appDidMount() {
				waitForNavigationToNonProtectedPage.then(() =>
					Object.entries(tpaWorkers).map(([applicationId, workerData]) =>
						createWorker({ applicationId, ...workerData })
					)
				)
			},
			async appWillLoadPage() {
				if (currentRouteInfo.getCurrentRouteInfo()) {
					resolveNavigationToNonProtectedPagePromise()
				}
			},
			getWorkerDetails(compId) {
				const applicationId = tpaWorkerCompIdRegex.exec(compId)?.[1]
				return tpaWorkers[applicationId!]
			},
			isTpaWorker,
		}
	}
)
